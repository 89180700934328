require('./bootstrap');
require('./script');
require("datatables.net-bs4/js/dataTables.bootstrap4");
window.WOW = require("wowjs").WOW;

//Data tables
$(document).ready(function(){
    $("#dataTables").DataTable();
})

$(document).ready(function(){
    $("#trashed").DataTable();
})

$(document).ready(function(){
    $(".dataTables").DataTable();
})
