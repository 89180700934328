$(window).on("load", function () {
    new WOW().init();
});

//Admin Sidebar  Script
window.addEventListener("DOMContentLoaded", (event) => {
    // Toggle the side navigation
    const sidebarToggle = document.body.querySelector("#sidebarToggle");
    if (sidebarToggle) {
        if (localStorage.getItem("sb|sidebar-toggle") === "true") {
            document.body.classList.toggle("sb-sidenav-toggled");
        }
        sidebarToggle.addEventListener("click", (event) => {
            event.preventDefault();
            document.body.classList.toggle("sb-sidenav-toggled");
            localStorage.setItem(
                "sb|sidebar-toggle",
                document.body.classList.contains("sb-sidenav-toggled")
            );
        });
    }
});

$(document).ready(function () {
    $(function () {
        new WOW().init();
    });
    $("#alert").modal();
    $("#alert").modal("show");
    var maxWidth = window.matchMedia("(max-width: 992px)");
    if(maxWidth.matches){
        $(".navChange").addClass("backgroundResp");
    }
    else{
        $(".navChange").removeClass("backgroundResp");
    }
});

$(window).on('resize', function(){
    var maxWidth = window.matchMedia("(max-width: 992px)");
    if(maxWidth.matches){
        $(".navChange").addClass("backgroundResp");
    }
    else{
        $(".navChange").removeClass("backgroundResp");
    }
});

$(window).scroll(function(){
    if($(this).scrollTop()>50){
        $('.navChange').css("background", "-webkit-linear-gradient(180deg, rgba(1,95,128,1) 0%, rgba(5,142,204,1) 69%, rgba(78,193,193,1) 100%)");
    }
    else{
        $('.navChange').css("background", "transparent");
    }
});

